<template>
  <div class="ant-card" id="course_category">
    <div v-if="$route.meta.isShow" class="ant-card-body">
      <div class="head-box">
        <div class="table-operator">
          <router-link v-if="power.add" to="/indexManage/indexOperate/addOperate/0">
            <a-button icon="plus" type="primary">新建活动</a-button>
          </router-link>
        </div>
        <div class="search-box">
          <a-input v-model="filter.name" placeholder="请输入活动名称" style="width: 200px; margin-right: 10px">
            <a-icon slot="prefix" type="search"/>
          </a-input>
          <a-button icon="search" type="primary" @click="onSearch">搜索</a-button>
        </div>
      </div>
      <div class="contant-box">
        <a-table
          ref="table"
          :columns="columns"
          :data-source="tableList"
          :loading="tableLoading"
          :row-key="record => record.id"
          :pagination="false"
        >
          <span slot="state" slot-scope="state, record">
            {{ stateMap.get(+state)}}
          </span>
          <span slot="action" slot-scope="text, record">
            <template>
              <router-link class="margin_right10" tag="a" :to="`/indexManage/indexOperate/addOperate/${record.id}?readonly=true`">查看</router-link>
              <a-popconfirm
                v-if="power.publish && +record.state !== 3  "
                :title="`确定要${+record.state === 1 ? '取消发布' : '发布'}`"
                ok-text="确定"
                cancel-text="取消"
                class="margin_right10"
                @confirm="pushHomeActive(record)"
              >
                <a>{{+record.state === 1 ? '取消发布' : '发布'}}</a>
              </a-popconfirm>
              <router-link v-if="power.edit && +record.state !== 3" tag="a" :to="`/indexManage/indexOperate/addOperate/${record.id}`">编辑</router-link>
            </template>
          </span>
        </a-table>
        <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
      </div>
    </div>
    <router-view v-else/>
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { HomeActiveListAPI, PushHomeActiveAPI } from '@/request/api/indexManage'
import { operateColumns } from './constants'
import { codeFn } from "@/utils/tools"
const stateMap = new Map([
  [1, '已发布'],
  [2, '未发布'],
  [3, '已过期']
])
export default {
  components: { MyPagination },
  computed: {
    isShow() {
      return this.$route.meta.isShow
    }
  },
  watch: {
    isShow(val) {
      if (val) this.fetchData()
    }
  },
  data() {
    return {
      power: {
        add: false,
        edit: false,
        publish: false
      },
      spinning: false, // 全局loading
      columns: operateColumns,
      stateMap,
      tableList: [],
      tableLoading: true,
      confirmLoading: false,
      filter: {
        name: ''
      },
      pageNo: 1,
      pageSize: 20,
      count: 0
    };
  },
  created() {
    if (codeFn('addOperate')) this.power.add = true
    if (codeFn('editOperate')) this.power.edit = true
    if (codeFn('publishOperate')) this.power.publish = true
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    onSearch() {
      this.pageNo = 1
      this.fetchData()
    },
    async fetchData() {
      this.tableLoading = true
      const params = {
        name: this.filter.name,
        pageSize: this.pageSize,
        pageNo: this.pageNo
      }
      const { code, data } = await HomeActiveListAPI(params)
      this.tableLoading = false
      if (code !== 200) return
      this.tableList = data.lists
      this.count = data.count
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.fetchData()
    },
    async pushHomeActive(record) {
      const { code } = await PushHomeActiveAPI({id: record.id})
      if (code !== 200) return
      this.$message.success(`${record.state === 1 ? '取消' : '发布'}成功`)
      this.fetchData()
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#course");
    },
  }
};
</script>

<style lang="less" scoped>
#course_category {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.head-box {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.btn-edit {
  margin-right: 40px;
}
</style>
